import { FilterButton, MoreBar, TableWidget } from '@revolut/ui-kit'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import React from 'react'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  performanceReviewEmployeeColumn,
  performanceReviewReviewerColumn,
  performanceReviewStatusColumn,
} from '@src/constants/columns/performanceReviews'
import {
  PerformanceReviewInterface,
  PerformanceReviewStats,
} from '@src/interfaces/performance'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { ROUTES } from '@src/constants/routes'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import {
  getAllProbationReviewsStats,
  getSubmittableProbationReviews,
} from '@src/api/probationReview'
import {
  probationReviewCheckpointColumn,
  probationReviewRoleColumn,
  probationReviewSeniorityColumn,
  probationReviewTeamColumn,
  probationReviewTypeColumn,
} from '@src/constants/columns/probation'
import { calculateCompletion } from '@src/pages/Performance/Reviews/Reviews'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { Location } from 'history'
import { useHasNewScorecards } from '@src/utils/performance'
import { useLocation } from 'react-router-dom'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'

const row = (
  currentUserId: number,
  location: Location<unknown>,
  showNewScorecards: boolean,
): RowInterface<PerformanceReviewInterface> => ({
  linkToForm: data => {
    const cycleId = data.performance_checkpoint?.employee_cycle_id
      ? data.performance_checkpoint?.employee_cycle_id
      : `E-${data.performance_checkpoint?.cycle_id}`
    const checkpointNum = data.performance_checkpoint?.number

    if (
      (data.status === Statuses.pending || data.status === Statuses.draft) &&
      currentUserId === data.reviewer.id
    ) {
      let path
      switch (data.category) {
        case ReviewCycleCategory.Probation:
          path = showNewScorecards
            ? ROUTES.FORMS.PROBATION_REVIEW_LAYOUT
            : ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES
          break
        case ReviewCycleCategory.PIP:
        case ReviewCycleCategory.PIP_V2:
          path = showNewScorecards
            ? ROUTES.FORMS.PIP_REVIEW_LAYOUT
            : ROUTES.FORMS.PIP_REVIEW.DELIVERABLES
          break
      }

      if (path) {
        return navigateTo(
          pathToUrl(path, {
            employeeId: data.reviewed_employee.id,
            id: data.id,
          }),
        )
      }
      return null
    }

    if (data.status === Statuses.completed || data.status === Statuses.completed_late) {
      const performanceLink = getReviewsUrl(
        data.category,
        cycleId,
        data.reviewed_employee.id,
        location,
        showNewScorecards,
      )

      const query = checkpointNum
        ? `reviewer_id=${data.reviewer.id}&checkpoint=${checkpointNum}`
        : 'reviewer_id='

      return navigateTo(`${performanceLink}?${query}`)
    }

    let path
    switch (data.category) {
      case ReviewCycleCategory.Probation:
        path = ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT
        break
      case ReviewCycleCategory.PIP:
      case ReviewCycleCategory.PIP_V2:
        path = ROUTES.FORMS.PIP_OVERVIEW.CHECKPOINT
        break
    }
    if (path) {
      return navigateTo(
        pathToUrl(path, {
          employeeId: data.reviewed_employee.id,
          cycleId,
          id: checkpointNum,
        }),
      )
    }
    return null
  },
  cells: [
    {
      ...performanceReviewEmployeeColumn,
      width: 200,
    },
    {
      ...probationReviewTypeColumn,
      width: 100,
    },
    {
      ...probationReviewCheckpointColumn,
      width: 100,
    },
    {
      ...performanceReviewReviewerColumn,
      width: 200,
    },
    {
      ...probationReviewSeniorityColumn,
      width: 100,
    },
    {
      ...probationReviewTeamColumn,
      width: 150,
    },
    {
      ...probationReviewRoleColumn,
      width: 150,
    },
    {
      ...performanceReviewStatusColumn,
      width: 100,
    },
  ],
})

interface ReviewsCommonProps {
  category: 'probation' | 'pip'
}

const ReviewsCommonTable = ({ category }: ReviewsCommonProps) => {
  const showNewScorecards = useHasNewScorecards()
  const location = useLocation()
  const permissions = useSelector(selectPermissions)
  const currentUser = useSelector(selectUser)

  const { data: performanceSettings } = useGetPerformanceSettings()
  const canViewCycles = permissions.includes(PermissionTypes.ViewReviewCycles)
  const probationEnabled = performanceSettings?.enable_probation
  const pipEnabled = performanceSettings?.enable_pip
  const canViewPipeline =
    canViewCycles && (category === 'probation' ? probationEnabled : pipEnabled)

  const initialFilters = [
    {
      columnName: 'category',
      filters: [{ name: category, id: category }],
      nonResettable: true,
    },
    {
      columnName: 'reviewer__id',
      filters: [{ name: currentUser.display_name, id: currentUser.id }],
      nonResettable: true,
    },
    {
      columnName: 'status',
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.draft, id: Statuses.draft },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      nonResettable: false,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'reviewed_employee__full_name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'performance_checkpoint__number',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<PerformanceReviewInterface, PerformanceReviewStats>(
    {
      getItems: getSubmittableProbationReviews,
      getStats: getAllProbationReviewsStats,
    },
    initialFilters,
    initialSortBy,
  )

  const filtersByReviewer =
    table.filterBy.find(filterBy => filterBy.columnName === 'reviewer__id')?.filters || []
  const isFilteredByMyReviews = filtersByReviewer.some(
    filter => filter.id === currentUser.id,
  )

  const toggleFilterByReviews = () => {
    table.onFilterChange(
      isFilteredByMyReviews
        ? [...table.filterBy, { columnName: 'reviewer__id', filters: [] }]
        : [
            ...table.filterBy,
            {
              columnName: 'reviewer__id',
              filters: [{ name: currentUser.display_name, id: currentUser.id }],
            },
          ],
    )
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Review completion" val={calculateCompletion(table.stats)} />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canViewPipeline && (
            <MoreBar.Action
              to={pathToUrl(
                category === 'probation'
                  ? ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PROBATION
                  : ROUTES.PERFORMANCE.MANAGE_REVIEWS.MANAGE_PIP,
              )}
              use={InternalLink}
              useIcon="16/ListBullet"
            >
              View pipeline
            </MoreBar.Action>
          )}
          <SettingsButton
            path={
              category === 'probation'
                ? ROUTES.PERFORMANCE.SETTINGS.PROBATION
                : ROUTES.PERFORMANCE.SETTINGS.PIP
            }
            canView={settingsConfig.performance.canView}
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        <FilterButton active={isFilteredByMyReviews} onClick={toggleFilterByReviews}>
          My reviews
        </FilterButton>
      </TableWidget.Filters>

      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.PerformanceReviews}
          useWindowScroll
          row={row(currentUser.id, location, showNewScorecards)}
          {...table}
          noDataMessage="All performance reviews will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const ReviewsCommon = ({ category }: ReviewsCommonProps) => {
  return (
    <OnboardingAppScreen category="reviews">
      <ReviewsCommonTable category={category} />
    </OnboardingAppScreen>
  )
}
